import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Reparations from '../views/Reparations'
import Contact from '../views/Contact'
import Qui from '../views/Qui'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'accueil',
    component: Home
  },
  {
    path: '/qui',
    name: 'qui',
    component: Qui
  },
  {
    path: '/reparations/:type?/:modele?',
    name: 'reparations',
    component: Reparations,
    props: true
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  { path: "*", redirect: { name: 'accueil' } }
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: "",
  linkExactActiveClass: "active",
  base: process.env.NODE_ENV === 'production' ? "/" : "/",
  routes
})

export default router
