import Vue from 'vue'
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import App from './App.vue'
import router from './router'
import store from './store'
import VueFormGenerator from 'vue-form-generator'
import 'vue-form-generator/dist/vfg.css'
import { createDateFilter } from "vue-date-fns";
import VueDateFns from "vue-date-fns";
import VueAnalytics from 'vue-analytics'
import VueScrollTo from 'vue-scrollto'
import VueGtm from '@gtm-support/vue2-gtm';

Vue.config.productionTip = false

const isProd = process.env.NODE_ENV === 'production'

Vue.use(VueGtm, {
  id: 'GTM-KVRQ5MR',
  queryParams: {
    // Add url query string when load gtm.js with GTM ID (optional)
    // gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    // gtm_preview: 'env-4',
    // gtm_cookies_win: 'x'
  },
  enabled: isProd, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});

Vue.use(VueAnalytics, {
  id: 'UA-138069741-3',
  router,
  autoTracking: {
    screenview: false,
    exception: true
  },
  debug: {
    enabled: false,
    sendHitTask: isProd
  }
})

Sentry.init({
  dsn: 'https://a893df9a0c7a4ee9bdf7669d3c166fcd@sentry.io/2193212',
  integrations: [new Integrations.Vue({Vue, attachProps: true, logError:true })],
  environment: process.env.VUE_APP_ENV
});

Vue.use(VueScrollTo, {})
Vue.use(VueFormGenerator)
Vue.use(VueDateFns);
Vue.filter("date", createDateFilter("DD/MM/YYYY"));

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
