import axios from 'axios'


export const HTTP = axios.create({
  baseURL: `https://www.macinstore.fr/crm-api/api/`
})

export const get = (url) => {
  return  new Promise((resolve, reject) => {
    HTTP.get(url)
    .then((response) => { resolve(response) })
    .catch((error) => { reject(error.response.data) })
  })
}

export const getTypes = () => {
  return get('/website_types')
}
