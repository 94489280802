<template>
  <div>
    <header class="py-4" style="height:300px;background-size: cover;" :style="{ backgroundImage: 'url(' + require('@/assets/devanture.jpg') + ')'}">
    </header>
    
    <!-- Content section -->
    <section class="py-5">
      <div class="container">
        <div class="row text-center">
          <div class="offset-lg-1 col-6 col-lg-3">
            <p><img src="../assets/mail.png"></p>
            <p class="font-weight-bold text-nowrap">Mail :</p>
            <p><a href="mailto:contact@macinstore.fr">contact@macinstore.fr</a></p>
          </div>
          <div class="col-6 col-lg-4">
            <p><img src="../assets/phone.png"></p>
            <p class="font-weight-bold text-nowrap">Téléphone :</p>
            <p><a href="tel:0145317839">01 45 31 78 39</a></p>
            <p><a target="_blank" :href="whatsAppUrl"><i class="fab fa-whatsapp fa-lg align-middle" style="color: #80D75C;"></i></a> <a target="_blank" :href="whatsAppUrl">WhatsApp</a></p>           
          </div>
          <div class="col-12 col-lg-3">
            <p><img src="../assets/gps.png"></p>
            <p class="font-weight-bold text-nowrap">Adresse :</p>
            <p>15, Rue Linné</p>
            <p>75005 Paris</p>
            <p>
              <svg style="vertical-align: text-bottom;" class="mr-1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 160 160" enable-background="new 0 0 160 160" xml:space="preserve">
                <g id="Logos"><g><path fill="#f2a4b6" d="M160,80.405C160,36,122.802-0.002,80.202-0.002C36.003-0.002,0,36,0,80.405
                    C0,124.404,36.003,160,80.202,160C124.401,160,160,124.604,160,80.405z M149,80.405c0,37.6-30.994,68.998-68.799,68.998
                    c-38.002,0-69.203-31.001-69.203-68.998c0-38.21,31.201-69.802,69.203-69.802C118.006,10.604,149,42.404,149,80.405z
                    M119.802,115.804V46c0-4.001-2.197-8.201-8.797-8.201c-5,0-7.002,2.199-9.204,6.601L80.202,89.403h-0.205l-21.79-45.004
                    c-2.205-4.401-4.208-6.601-9.207-6.601c-6.602,0-8.799,4.2-8.799,8.201v69.804c0,3.799,3.002,6.002,6.601,6.002
                    c3.206,0,6.798-2.203,6.798-6.002V63.803h0.203l19.801,40.398c1.397,2.799,3.197,4.399,6.395,4.399
                    c3.204,0,5.002-1.601,6.401-4.399l19.808-40.398h0.198v52.001c0,3.799,3.595,6.002,6.801,6.002
                    C116.799,121.806,119.802,119.603,119.802,115.804z"/></g></g>
              </svg>
              <svg style="vertical-align: text-bottom;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 160 160" enable-background="new 0 0 160 160" xml:space="preserve">
                <g id="Logos"><g><path fill="#e4b427" d="M160,80.405C160,36,122.802-0.002,80.202-0.002C36.003-0.002,0,36,0,80.405
                    C0,124.404,36.003,160,80.202,160C124.401,160,160,124.604,160,80.405z M149,80.405c0,37.6-30.994,68.998-68.799,68.998
                    c-38.002,0-69.203-31.001-69.203-68.998c0-38.21,31.201-69.802,69.203-69.802C118.006,10.604,149,42.404,149,80.405z
                    M119.802,115.804V46c0-4.001-2.197-8.201-8.797-8.201c-5,0-7.002,2.199-9.204,6.601L80.202,89.403h-0.205l-21.79-45.004
                    c-2.205-4.401-4.208-6.601-9.207-6.601c-6.602,0-8.799,4.2-8.799,8.201v69.804c0,3.799,3.002,6.002,6.601,6.002
                    c3.206,0,6.798-2.203,6.798-6.002V63.803h0.203l19.801,40.398c1.397,2.799,3.197,4.399,6.395,4.399
                    c3.204,0,5.002-1.601,6.401-4.399l19.808-40.398h0.198v52.001c0,3.799,3.595,6.002,6.801,6.002
                    C116.799,121.806,119.802,119.603,119.802,115.804z"/></g></g>
              </svg>
              Jussieu
            </p>
            <p class="font-weight-bold text-nowrap">Heures d’ouvertures :</p>
            <p>Du lundi au samedi : 10h00 - 19h00</p>
            <p class="mt-3"><a target="_blank" href="https://g.page/MacinstoreFR" class="btn text-nowrap" style="font-size:18px;line-height:24px;">Voir sur Google Maps</a></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { whatsAppUrl } from '@/helper.js';

export default {
  components: {
    
  },
  data () {
    return {
      whatsAppUrl: whatsAppUrl
    }
  },
  computed: {
  },
  mounted() {
    
  }
}
</script>
