<template>
    <nav class="navbar navbar-light navbar-expand-lg bg-white fixed-top">
      <div class="container">
        <a class="navbar-brand" href="/"><img src="../assets/logo.svg" height="60px"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <NavigationLinks></NavigationLinks>
        </div>
      </div>
    </nav>
</template>

<script>
import NavigationLinks from '@/components/NavigationLinks'

export default {
  components: {
    NavigationLinks
  },
  computed: {
  },
  mounted() {
    
  }
}
</script>