<template>
  <ul class="navbar-nav ml-auto">
    <router-link tag="li" class="nav-item mx-5" to="/" @click.native="sleep().then(() => { $scrollTo('#up', { offset: -90 }) })" data-toggle="collapse" data-target="#navbarResponsive">
      <a class="nav-link text-nowrap">Accueil</a>
    </router-link>
    <router-link tag="li" class="nav-item mx-5" to="/reparations" @click.native="sleep().then(() => { $scrollTo('#up', { offset: -90 }) })" data-toggle="collapse" data-target="#navbarResponsive">
      <a class="nav-link text-nowrap">Réparations</a>
    </router-link>
    <router-link tag="li" class="nav-item mx-5" to="/qui" @click.native="sleep().then(() => { $scrollTo('#up', { offset: -90 }) })" data-toggle="collapse" data-target="#navbarResponsive">
      <a class="nav-link text-nowrap">Qui sommes nous ?</a>
    </router-link>
    <router-link tag="li" class="nav-item mx-5" to="/contact" @click.native="sleep().then(() => { $scrollTo('#up', { offset: -90 }) })" data-toggle="collapse" data-target="#navbarResponsive">
      <a class="nav-link text-nowrap">Contact</a>
    </router-link>
  </ul>
</template>

<script>
export default {
  methods: {  
    sleep: function(ms = 100) {  
      return new Promise(resolve => setTimeout(resolve, ms))
    }
  }
}
</script>

<style>
a.nav-link {
  text-transform: uppercase;
  color: black;
  line-height: 30px;
}
a.nav-link span:hover {
  border-bottom: 2px solid #EAE1D7;
  display: inline-block;
}
</style>