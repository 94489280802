<template>
  <div>
    <!-- Header - set the background image for the header in the line below -->
    <header class="py-4" style="margin-bottom: 30px;padding-right: 20px;padding-left: 20px;background-size: cover;" :style="{ backgroundImage: 'url(' + require('@/assets/clavier.jpg') + ')'}">
      <div class="container">
        <div class="row">
          <!-- <div class="col-12 col-md-7">
            <img class="img-fluid d-block mx-auto" src="../assets/clavier.jpg" alt="" style="width: 100%;">
          </div> -->
          <div class="col-12 order-first order-md-last text-white text-center">
            <h1 class="mt-md-4 mb-4 font-weight-bold" style="font-size:40px;">Votre spécialiste<br>informatique et téléphonie</h1>
            <h6 class="ml-4 mb-4">Réparation de tous vos iDevices / Assistance informatique personnelle<br>
              ou professionnelle / Formation tous niveaux</h6>
            <div class="text-center" style="padding-top: 30px;">
              <router-link class="btn text-white" style="border-color:white" tag="button" to="/reparations">Nos réparations</router-link>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section class="py-4" style="margin-top:30px;">
       <div class="container">
         <div class="row">
          <div class="col text-center">
            <h1 id="engagement" class="text-center text-bold" style="margin-bottom: 20px;">
              <span style="border-bottom: 2px solid #B89994;">Notre engagement</span>
            </h1>
          </div>
         </div>
         <div class="row">
          <div class="col-12 col-md-4 p-3 text-center">
            <div class="p-4" style="border: 1px solid #C4C4C4;">
              <img src="../assets/settings.svg" style="width: 60px;">
              <div class="expertise">Un service personnalisé</div>
              <div class="expertisesubline">Avec plus de 25 ans d’expérience en numérique et téléphonie, c’est avec bienveillance que nous prenons soin de tous vos appareils. </div>
            </div>
          </div>
          <div class="col-12 col-md-4 p-3 text-center">
            <div class="p-4" style="border: 1px solid #C4C4C4;">
              <img src="../assets/clock.svg" style="width: 60px;">
              <div class="expertise">Une intervention rapide</div>
              <div class="expertisesubline">Vous rendre service, et ce dans les plus brefs délais, reste notre priorité.<br><br><br></div>
            </div>
          </div>
          <div class="col-12 col-md-4 p-3 text-center">
            <div class="p-4" style="border: 1px solid #C4C4C4;">
              <img src="../assets/credit-card.svg" style="width: 60px;">
              <div class="expertise">Un prix défiant toute concurrence</div>
              <div class="expertisesubline">Nous pratiquons une politique tarifaire juste, en concordance avec des produits et un service de qualité irréprochable.<br><br></div>
            </div>
          </div>
         </div>
       </div>
    </section>

    <!-- Content section -->
    <section class="py-4" style="">
      <div class="container">
        <h1 id="qui" class="text-center" style="margin-bottom: 50px;">
          <span style="border-bottom: 2px solid #B89994;">Nous rendre visite</span>
        </h1>
        <div class="row">
          <div class="offset-md-1 col-12 col-md-5 order-12 order-md-0" style="min-height:400px;">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.7131707474996!2d2.352436915554491!3d48.84460957928616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6711c2f19fb77%3A0x342cfc8dfd3008a!2sMacinstore!5e0!3m2!1sfr!2sfr!4v1582574533905!5m2!1sfr!2sfr" width="100%" height="100%" frameborder="0" style="border:0;border-radius: 20px;" allowfullscreen=""></iframe>
          </div>
          <div class="col-12 col-md-5 my-auto text-center pb-2">
            <div class="mb-5" style="font-size: 30px;line-height: 35px;">
              15, rue Linné<br>75005 Paris
              <p>
                <svg style="vertical-align: text-bottom;" class="mr-1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 160 160" enable-background="new 0 0 160 160" xml:space="preserve">
                  <g id="Logos"><g><path fill="#f2a4b6" d="M160,80.405C160,36,122.802-0.002,80.202-0.002C36.003-0.002,0,36,0,80.405
                      C0,124.404,36.003,160,80.202,160C124.401,160,160,124.604,160,80.405z M149,80.405c0,37.6-30.994,68.998-68.799,68.998
                      c-38.002,0-69.203-31.001-69.203-68.998c0-38.21,31.201-69.802,69.203-69.802C118.006,10.604,149,42.404,149,80.405z
                      M119.802,115.804V46c0-4.001-2.197-8.201-8.797-8.201c-5,0-7.002,2.199-9.204,6.601L80.202,89.403h-0.205l-21.79-45.004
                      c-2.205-4.401-4.208-6.601-9.207-6.601c-6.602,0-8.799,4.2-8.799,8.201v69.804c0,3.799,3.002,6.002,6.601,6.002
                      c3.206,0,6.798-2.203,6.798-6.002V63.803h0.203l19.801,40.398c1.397,2.799,3.197,4.399,6.395,4.399
                      c3.204,0,5.002-1.601,6.401-4.399l19.808-40.398h0.198v52.001c0,3.799,3.595,6.002,6.801,6.002
                      C116.799,121.806,119.802,119.603,119.802,115.804z"/></g></g>
                </svg>
                <svg style="vertical-align: text-bottom;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 160 160" enable-background="new 0 0 160 160" xml:space="preserve">
                  <g id="Logos"><g><path fill="#e4b427" d="M160,80.405C160,36,122.802-0.002,80.202-0.002C36.003-0.002,0,36,0,80.405
                      C0,124.404,36.003,160,80.202,160C124.401,160,160,124.604,160,80.405z M149,80.405c0,37.6-30.994,68.998-68.799,68.998
                      c-38.002,0-69.203-31.001-69.203-68.998c0-38.21,31.201-69.802,69.203-69.802C118.006,10.604,149,42.404,149,80.405z
                      M119.802,115.804V46c0-4.001-2.197-8.201-8.797-8.201c-5,0-7.002,2.199-9.204,6.601L80.202,89.403h-0.205l-21.79-45.004
                      c-2.205-4.401-4.208-6.601-9.207-6.601c-6.602,0-8.799,4.2-8.799,8.201v69.804c0,3.799,3.002,6.002,6.601,6.002
                      c3.206,0,6.798-2.203,6.798-6.002V63.803h0.203l19.801,40.398c1.397,2.799,3.197,4.399,6.395,4.399
                      c3.204,0,5.002-1.601,6.401-4.399l19.808-40.398h0.198v52.001c0,3.799,3.595,6.002,6.801,6.002
                      C116.799,121.806,119.802,119.603,119.802,115.804z"/></g></g>
                </svg>
                <span style="font-size: 14px; padding-left: 5px;">Jussieu</span>
              </p>
            </div>
            <div>
              <a target="_blank" href="https://g.page/MacinstoreFR" class="btn" style="font-size:18px;line-height:24px;">Ouvrir avec<br>Google Maps</a>
            </div>
          </div>
          <div class="text-center mx-auto" style="margin-top:30px;">
            <h1 id="qui" class="text-center" style="margin-bottom: 50px;">
              <span style="border-bottom: 2px solid #B89994;">Ce que pensent nos clients</span>
            </h1>
            <div class="mt-2 offset-md-3 col-md-6">
              <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-pause="false" data-interval="5000">
                <div class="carousel-inner">
                  <div style="" class="carousel-item" :class="{ 'active': index === 0 }" v-for="(review, index) in reviews" :key="review.time">
                    <div class="">
                    <p>{{review.text}}</p>
                    <p style="color:#CD7028;"><span class="pr-2">{{review.rating.toFixed(1)}}</span>
                      <i class="fa fa-star" v-for="n in review.rating" :key="n"></i>
                      <i class="far fa-star" v-for="n in 5-review.rating" :key="n"></i>
                    </p>
                    </div>
                  </div>
                </div>
                <!-- <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a> -->
              </div>
              <div id="google-reviews">

                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Content section -->
    <section class="mt-5" style="padding-bottom: 5rem!important;padding-top: 5rem!important;" :style="{ backgroundImage: 'url(' + require('@/assets/desk.jpg') + ')' }">
      <div class="container text-center">
        <h2 id="contact" class="text-white font-weight-bold" style="">Besoin d’une formation ?</h2>
        <p class="text-white mt-5" style="font-size: 20px;text-shadow: black 0px 1px;">Nous vous formons à l’utilisation<br>d’outils éléctroniques en magasin<br>ou à domicile. </p>
        <div class="text-center" style="padding-top: 30px;">
          <a href="mailto:contact@macinstore.fr" class="btn text-white" style="border-color:white">Demander un devis</a>
        </div>
      </div>
    </section>

    <!-- Content section -->
    <section class="py-4" style="background-color: #F2F2F2">
      <div class="container-fluid">
        <div class="row text-center text-md-left">
          <div class="offset-lg-1 col-12 col-lg-3 text-center order-12 order-lg-0">
            <p class="font-weight-bold">Macinstore</p>
            <p>&copy; {{currentYear}}</p>
            <!-- <p>Privacy — Terms</p> -->
          </div>
          <div class="col-12 col-md-6 col-lg-3 offset-lg-1">
            <p class="font-weight-bold">Adresse :</p>
            <p>15, Rue Linné</p>
            <p>75005 Paris</p>
            <p>
              <svg style="vertical-align: text-bottom;" class="mr-1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 160 160" enable-background="new 0 0 160 160" xml:space="preserve">
                <g id="Logos"><g><path fill="#f2a4b6" d="M160,80.405C160,36,122.802-0.002,80.202-0.002C36.003-0.002,0,36,0,80.405
                    C0,124.404,36.003,160,80.202,160C124.401,160,160,124.604,160,80.405z M149,80.405c0,37.6-30.994,68.998-68.799,68.998
                    c-38.002,0-69.203-31.001-69.203-68.998c0-38.21,31.201-69.802,69.203-69.802C118.006,10.604,149,42.404,149,80.405z
                    M119.802,115.804V46c0-4.001-2.197-8.201-8.797-8.201c-5,0-7.002,2.199-9.204,6.601L80.202,89.403h-0.205l-21.79-45.004
                    c-2.205-4.401-4.208-6.601-9.207-6.601c-6.602,0-8.799,4.2-8.799,8.201v69.804c0,3.799,3.002,6.002,6.601,6.002
                    c3.206,0,6.798-2.203,6.798-6.002V63.803h0.203l19.801,40.398c1.397,2.799,3.197,4.399,6.395,4.399
                    c3.204,0,5.002-1.601,6.401-4.399l19.808-40.398h0.198v52.001c0,3.799,3.595,6.002,6.801,6.002
                    C116.799,121.806,119.802,119.603,119.802,115.804z"/></g></g>
              </svg>
              <svg style="vertical-align: text-bottom;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 160 160" enable-background="new 0 0 160 160" xml:space="preserve">
                <g id="Logos"><g><path fill="#e4b427" d="M160,80.405C160,36,122.802-0.002,80.202-0.002C36.003-0.002,0,36,0,80.405
                    C0,124.404,36.003,160,80.202,160C124.401,160,160,124.604,160,80.405z M149,80.405c0,37.6-30.994,68.998-68.799,68.998
                    c-38.002,0-69.203-31.001-69.203-68.998c0-38.21,31.201-69.802,69.203-69.802C118.006,10.604,149,42.404,149,80.405z
                    M119.802,115.804V46c0-4.001-2.197-8.201-8.797-8.201c-5,0-7.002,2.199-9.204,6.601L80.202,89.403h-0.205l-21.79-45.004
                    c-2.205-4.401-4.208-6.601-9.207-6.601c-6.602,0-8.799,4.2-8.799,8.201v69.804c0,3.799,3.002,6.002,6.601,6.002
                    c3.206,0,6.798-2.203,6.798-6.002V63.803h0.203l19.801,40.398c1.397,2.799,3.197,4.399,6.395,4.399
                    c3.204,0,5.002-1.601,6.401-4.399l19.808-40.398h0.198v52.001c0,3.799,3.595,6.002,6.801,6.002
                    C116.799,121.806,119.802,119.603,119.802,115.804z"/></g></g>
              </svg>
              Jussieu
            </p>
            <p class="font-weight-bold">Heures d'ouvertures :</p>
            <p>Du lundi au samedi : 10h00 - 19h00</p>
          </div>
          <div class="col-12 col-md-5 col-lg-3 offset-md-1">
            <p class="font-weight-bold">Mail :</p>
            <p><a href="mailto:contact@macinstore.fr">contact@macinstore.fr</a></p>
            <p class="font-weight-bold">Téléphone :</p>
            <p><a href="tel:0145317839">01 45 31 78 39</a></p>
            <p class=""><a target="_blank" :href="whatsAppUrl"><i class="fab fa-whatsapp fa-lg align-middle" style="color:#80D75C;"></i></a> <a target="_blank" :href="whatsAppUrl">WhatsApp</a></p>           
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { whatsAppUrl } from '@/helper.js';

/*global google */
export default {
  components: {
    
  },
  data () {
    return {
      reviews: [],
      whatsAppUrl: whatsAppUrl,
      currentYear: new Date().getFullYear()
    }
  },
  computed: {
  },
  mounted() {
    var map = new google.maps.Map(document.getElementById('google-reviews'));

    var request = {
      placeId: 'ChIJd_sZLxxx5kcRigDT38jPQgM'
    };

    /*  */
    var service = new google.maps.places.PlacesService(map);

    service.getDetails(request, (place, status) => {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        // c(place);
        this.reviews = place.reviews.filter((review) => review.rating == 5)
        // console.log(place.reviews);
      }
    });
  }
}
</script>

<style>
  .btn {
    border: 1px solid #333334;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 22px;
    line-height: 30px;
    color: #333334;
    border-radius: 6px;
  }

  .btn:hover {
    background-color: #EAE1D7;
    color: #333334!important;
    border-color: black!important;
  }

  .btn:focus {
    box-shadow: none;
  }

  .btn-alt {
    color: #FFFFFF;
    border: 1px solid #333334;
    background: #333334;
    border-radius: 5px;font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
  }

  .btn-alt:hover {
    background-color: #EAE1D7;
  }

  .carousel-caption{
    color:#000;
    position:static;
  }
/* 
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff00' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  }

  .carousel-control-next-icon  {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff00' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
  }

  .carousel-control-prev {
    left: -50px;
  }

  .carousel-control-next {
    right: -50px;
  } */
</style>

<style scoped>
.expertise {
  font-size:18px;
  line-height:24px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top:10px;
}

.expertisesubline {
  font-size: 14px;
  line-height:20px;
}


</style>