<template>
  <div>
    <section class="py-5">
      <div class="container">
        <div class="row">
          <div class="col-6 d-none d-md-block">
            <img class="img-fluid" src="../assets/qui.png" >
          </div>
          <div class="cold-12 col-md-6">
            <div style="font-weight: 800;font-size: 14px;line-height: 18px;letter-spacing: 0.4px;text-transform: uppercase;color: #B89994;">à propos de macinstore</div>
            <div style="margin-top:12px;margin-bottom:40px;font-weight: bold;font-size: 44px;line-height: 56px;letter-spacing: 0.2px;color: #333334;">Le service<br>de père en fils</div>
            <img class="img-fluid d-block d-md-none" src="../assets/qui.png" >
            <div style="font-size: 16px;line-height: 22px;letter-spacing: 0.3px;color: #737B7D;">
              Macinstore, fondé par un fils et son père, respectivement Yaniv et Fabrice, offre un service de qualité, de la vente à la réparation en passant par l’assistance. Experts depuis plus de 25 ans en informatique, téléphonie, électronique et conseil, nous préconisons le recyclage de tous vos appareils afin d’éviter le gaspillage de matériel et d’argent. Ainsi, nous ne lésinons pas sur votre temps, proposons un accompagnement personnalisé, de qualité et économique à tout point de vue.
            </div>
            <a target="_blank" href="mailto:contact@macinstore.fr" class="mt-5 btn" style="font-size:18px;line-height:24px;">Nous contacter</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {
    
  },
  computed: {
  },
  mounted() {
    
  }
}
</script>
