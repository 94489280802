<template>
  <footer class="py-3 bg-dark" style="position: absolute;z-index: 9999;width: 100%;">
    <div class="container-fluid">
      <div class="row">
        <div class="pl-3 pl-sm-5 col-6 text-nowrap align-middle">
          <a target="_blank" :href="whatsAppUrl"><i class="fab fa-whatsapp fa-2x align-middle px-1 px-sm-3" style="color: #fff;"></i></a>
          <a target="_blank" href="https://www.facebook.com/MacinstoreFR"><i class="fab fa-facebook-f fa-2x align-middle px-1 px-sm-3" style="color: #fff;"></i></a>
          <a target="_blank" href="https://www.instagram.com/macinstorefr"><i class="fab fa-instagram fa-2x align-middle px-1 px-sm-3" style="color: #fff;"></i></a>
          <a target="_blank" href="https://twitter.com/macinstorefr"><i class="fab fa-twitter fa-2x align-middle px-1 px-sm-3" style="color: #fff;"></i></a>
          <a href="mailto:contact@macinstore.fr"><i class="far fa-envelope fa-2x align-middle px-1 px-sm-3" style="color: #fff;"></i></a>
        </div>
        <div class=" col-6 text-right pr-3 pr-sm-5">
          <table class="float-right text-white" style="font-size:12px;">
            <tr>
              <td rowspan="2" style="font-size:14px;" class="border-md-right pr-2">
                <a href="macinstore-cgv.pdf" target="_blank" class="text-white pr-1 pr-lg-5 d-none d-sm-inline" style="font-weight: 500;">CGV</a>
                <span class="text-nowrap">Macinstore &copy;<span class="d-none d-sm-inline"> {{currentYear}}</span></span>
                <a href="macinstore-cgv.pdf" target="_blank" class="text-white pr-1 pr-lg-5 d-block d-sm-none" style="font-weight: 500;">CGV</a>
              </td>
              <td class="d-none d-md-table-cell pl-2 text-nowrap">15, Rue Linné</td>
            </tr>
            <tr>
              <td class="d-none d-md-table-cell text-nowrap">75005 Paris</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { whatsAppUrl } from '@/helper.js';

export default {
  components: {
    
  },
  data () {
    return {
      whatsAppUrl: whatsAppUrl,
      currentYear: new Date().getFullYear()
    }
  },
  computed: {
  },
  mounted() {
    
  }
}
</script>

<style>
@media only screen and (min-width : 768px) {
  .border-md-right {
    border-right: 1px solid #dee2e6!important;
  }
}
</style>