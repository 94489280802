<template>
  <div>
    <section class="py-4" v-if="errorMessage.length > 0">
      <div class="container">
        <h3 class="text-center">Nos Réparations</h3>
        <div class="mt-5 alert alert-secondary mb-5" v-html="errorMessage"></div>
      </div>
    </section>
    <section v-else class="py-4">
      <div class="container">
        <h3 class="text-center">Nos Réparations</h3>
        <div v-if="errorMessage.length > 0" class="mt-5 alert alert-secondary" v-html="errorMessage"></div>
        <p class="lead  text-center" style="font-size: 16px;">Quel produit souhaitez-vous réparer ?</p>
      
        <div class="row-rep row mx-auto" style="width: 80%">
          <router-link @click.native="typeSelection" :to="{ path: '/reparations/'+current.id }" class="col-12 col-md-4 col-lg-3 py-lg-3 pt-4 text-center" style="cursor: pointer;" v-for="current in types" :key="current.id">
            <p><img class="img-fluid" :src="require('../assets/'+current.picture)"></p>
            <p><button class="btn">{{current.name}}</button></p>
          </router-link>
        </div>
      </div>

      <div id="modeles" v-if="selectedType != null" style="background-color:#FAFAFA;">
        <div v-if="selectedType.modeles.length > 0" class="container py-3" style="width: 80%">
          <h3 class="text-center">Réparation {{selectedType.name}}</h3>
          <p class="lead text-center py-4 py-md-0 model_question" style="font-size: 16px;">Quel modèle souhaitez-vous réparer ?</p>
          <div class="row">
            <div class="offset-1 col-5 text-right d-none d-md-block">
              <img class="img-fluid" style="width:100%" :src="require('../assets/'+selectedType.picture)">
            </div>
            <div class="col-12 col-md-5">
              <div class="form-group">
                <select v-model="currentModele" v-on:change="modeleSelection" class="form-control" id="exampleFormControlSelect1">
                  <option value="" selected disabled>Sélectionnez le modèle de votre {{selectedType.name}}</option>
                  <option v-for="current in selectedType.modeles" :key="current.id" :value="current.id">{{current.name}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="container py-3" style="width: 80%">
          <h3 class="text-center">Réparation {{selectedType.name}}</h3>
          <div class="lead text-center" style="font-size: 16px;">
            Pour toute réparation n'hesitez pas à nous 
            <router-link :to="{ name: 'contact'}" @click.native="sleep().then(() => { $scrollTo('#up', { offset: -90 }) })">contacter</router-link>.
            <br/><br/>
            <p class="text-nowrap">Mail :<a href="mailto:contact@macinstore.fr">contact@macinstore.fr</a></p>

            <p class="text-nowrap">Téléphone : <a href="tel:0145317839">01 45 31 78 39</a></p>
            <p><a target="_blank" :href="whatsAppUrl"><i class="fab fa-whatsapp fa-lg align-middle" style="color: #80D75C;"></i></a> <a target="_blank" :href="whatsAppUrl">WhatsApp</a></p>           

            <p class="text-nowrap">Adresse : 15, Rue Linné 75005 Paris</p>
          </div>
        </div>
      </div>

      <div id="tarifs" v-if="selectedModel != null" class="container py-3" itemscope itemtype="https://schema.org/Product">
        <h3 class="text-center">Nos Tarifs pour {{selectedModel.name}}</h3>
        <div class="d-flex flex-wrap justify-content-center mx-auto tarif-div">
          <div class="m-2 col-12 col-md-4 col-lg-3  px-3 text-center" :class="{ 'pt-4': isQualiReparElligible(current.name), 'py-4': !isQualiReparElligible(current.name) }" style="background-color: #EAE1D7;" v-for="current in selectedModel.tarifs" :key="current.name">
            <p style="font-size: 24px;line-height: 30px;height: 60px; overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;line-clamp: 2;" itemprop="name">{{formatName(current.name)}}</p>
            <p :class="{ 'mb-0': isQualiReparElligible(current.name) }" style="color: #AC5C5C;font-weight: bold;font-size: 32px;line-height: 37px;" itemprop="price">{{current.price}}€</p>
            <img v-if="isQualiReparElligible(current.name)" class="float-right mb-2" style="" :src="require('../assets/qualirepar-logo.png')">
          </div>
        </div>
        <div class="text-center blockquote-footer mt-3 qualirepar_footer">
          <img class="" style="height:20px;" :src="require('../assets/qualirepar-logo.png')"> Tarif éligible au bonus réparation. Réduction non appliqué sur le prix affiché. Plus de détails <u><a href="https://www.ecosystem.eco/reparer/liste-equipements" target="_blank">ici</a></u>.
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import { getTypes } from '@/api/crm-api.js'
import { whatsAppUrl } from '@/helper.js';

export default {
  props: {
    type: String,
    modele: String
  },
  data () {
    return {
      currentModele: '',
      errorMessage: '',
      types: [],
      whatsAppUrl: whatsAppUrl
    }
  },
  computed: {
    selectedType () {
      let founds = this.types.filter((x) => x.id == this.type);
      if (founds.length > 0) {
        return founds[0]
      }

      return null
    },
    selectedModel () {
      let foundType = this.selectedType
      if (foundType != null && foundType.modeles != null) {
        let founds = foundType.modeles.filter((x) => x.id == this.modele);

        if (founds.length > 0) {
          return founds[0]
        }
      }

      return null
    }
  },
  methods: {
    modeleSelection() {
      this.$router.push({path:'/reparations/' + this.type + '/' + this.currentModele }).catch(() => {})
      this.sleep().then(() => { this.$scrollTo("#tarifs", 500, { offset: -90 }) })
    },
    typeSelection () {
      this.currentModele = ''
      this.sleep().then(() => { this.$scrollTo("#modeles", 500, { offset: -90 }) })
    },
    sleep: function(ms = 100) {  
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    formatName: function(name) {
      return name.replace(/\*+$/, '')
    },
    isQualiReparElligible: function(name) {
      return name.endsWith('*')
    }

  },
  mounted () {
    this.errorMessage = '';
    if (this.selectedModel != null) {
      this.currentModele = this.modele
    } else {
      this.currentModele = ''
    }

    getTypes().then((response) => {
        this.types = response.data
      })
      .catch(() => {
        this.errorMessage = 'Veuillez nous excuser, une erreur est survenue.<br>Vous pouvez tout de même directement nous contacter au <a href="tel:0145317839">01 45 31 78 39</a> ou sur <a href="mailto:contact@macinstore.fr">contact@macinstore.fr</a>'
      })
  }
}
</script>

<style scoped>
.tarif-div {
    width: 80%;
}

@media only screen and (max-width : 768px) {
  .row-rep {
    width: 100%
  }

  /* .tarif-div {
    width: 100%;
  } */

  #modeles {
      min-height: 50vh;
  }

  .model_question {
    font-weight: bold;
  }

}

.form-control:focus {
    border-color: inherit;
    box-shadow: none;
}

.qualirepar_footer::before {
    content: "";
}
</style>