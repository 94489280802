<template>
  <div id="app" style="min-height: calc(100% - 226px);">
    <!-- <Banner /> -->
    <NavigationBig id="up" />
    <Navigation />
    <div id="main">
      <transition name="fade" mode="out-in"  @after-leave="afterLeave">
        <router-view class="view"/>
      </transition>
    </div>
    <Footer />
    <div style="position:fixed;bottom:15px;right:15px;">
      <a target="_blank" :href="whatsAppUrl">
        <img src="./assets/whatsappbubble.svg">
      </a>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue'
import NavigationBig from '@/components/NavigationBig.vue'
import Footer from '@/components/Footer.vue'
// import Banner from '@/components/Banner.vue'
import { whatsAppUrl } from '@/helper.js';

export default {
  components: {
    Navigation,
    NavigationBig,
    Footer
    // ,Banner
  },
  data () {
    return {
      whatsAppUrl: whatsAppUrl
    }
  },
  computed: {
  },
  methods: {
    afterLeave () {
      this.$root.$emit('triggerScroll')
    }
  },
  mounted() {
    
  }
}
</script>

<style>
html, body {
  height: 100%;
}

a {
  cursor: pointer;
  color: black;
}

a:hover {
  cursor: pointer;
  color: black;
}

#main {
    margin-top: 30px;
}

@media (min-width: 992px) {
  #main {
    margin-top: 140px;
  }
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    font-weight: 500 !important;
}

</style>